/* eslint-disable camelcase */
import React from 'react';
import { ContentWrapper } from '@global/styles/grid';
import { Markdown } from '@components/Common';
import { Script } from 'gatsby';

import {
  ContactContainer,
  TextContainer,
  TextContent,
  Wrapper,
  FormContainer,
  Title,
  SubTitle,
} from './EmbedFormModal.styles';
import { EmbedFormProps } from './EmbedFormModal.types';

import { HUBSPOT } from '@global/constants/urls';

const EmbedFormModal = ({ Hubspot_Form_ID, title, subtitle }: EmbedFormProps) => {
  const createForm = () => {
    const hubspotConfig: any = {
      region: 'na1',
      portalId: HUBSPOT.PORTAL_ID,
      formId: Hubspot_Form_ID,
      target: '#hubspotFormContainer',
    };
    hbspt.forms.create(hubspotConfig);
  };

  return (
    <ContactContainer>
      <Script src="//js.hsforms.net/forms/embed/v2.js" onLoad={() => createForm()} />
      <ContentWrapper>
        <Wrapper>
          <TextContainer>
            <TextContent>
              {title && (
                <Title>
                  <Markdown text={title} textColor="white" />
                </Title>
              )}
              {subtitle && (
                <SubTitle>
                  <Markdown text={subtitle} textColor="white" />
                </SubTitle>
              )}
              <FormContainer id="hubspotFormContainer" />
            </TextContent>
          </TextContainer>
        </Wrapper>
      </ContentWrapper>
    </ContactContainer>
  );
};

export default EmbedFormModal;
