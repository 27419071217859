const isBrowser = typeof window !== 'undefined';

export function getItem(key: string): null | string | JSON {
  if (isBrowser) {
    const item: string | null = localStorage.getItem(key);
    return !!item && typeof item === 'object' ? JSON.parse(item) : item;
  }
  return null;
}

export function setItem(key: string, value: string): void {
  if (isBrowser) {
    const item: string = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, item);
  }
}

export function removeItem(key: string): void {
  if (isBrowser) {
    localStorage.removeItem(key);
  }
}

export function clearItems(): void {
  if (isBrowser) {
    localStorage.clear();
  }
}
