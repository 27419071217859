import { COLORS } from '@global/styles/colors';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 440px;
  max-height: 385px;
  padding: 60px 30px;
  margin: 10px;
  background: ${COLORS.white};
  border-radius: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ModalTitle = styled.h3`
  width: 100%;
  margin-bottom: 5px;
  font: normal normal bold 30px/24px Montserrat;
  color: ${COLORS.gulfBlue};
  text-align: center;
`;

export const ModalSubtitle = styled.p`
  width: 100%;
  max-width: 315px;
  margin: 10px auto 0;
  font: normal normal 400 20px/24px Montserrat;
  color: ${COLORS.gulfBlue};
  text-align: center;
`;

export const CloseIcon = styled.img`
  width: 17px;
  height: 19px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

export const LottieContainer = styled.div`
  width: 100%;
  height: 165px;
  position: relative;
  svg {
    height: 340px !important;
  }
`;
