import styled from 'styled-components';

import { DESKTOP_1280, TABLET_992, TABLET_768 } from './sizes';

// Change the values of the ContentWrapper

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${DESKTOP_1280}px) {
    max-width: 960px;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 770px;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 90vw;
  }
`;
