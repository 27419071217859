import styled, { css } from 'styled-components';
import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { MOBILE_460, TABLET_800 } from '@global/styles/sizes';
import { IBulletICon } from '@sections/TextColumns/TextColumns.props';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;
export const FormContainer = styled.div`
  .hs-form-field {
    position: relative;

    label {
      display: none;
    }
  }
  .hs-fieldtype-checkbox {
    input[type='checkbox'] {
      height: 20px;
      width: 20px !important;
      margin: 6px 0;
    }
    label {
      display: flex;
      font: italic 200 18px/22px ${FONT.montserrat};
      color: ${COLORS.white};
      opacity: 0.9;

      span {
        margin-left: 14px;
        align-self: center;
        font: italic normal normal 18px/28px ${FONT.montserrat};
      }
    }
  }

  .hs-richtext {
    margin-bottom: 20px;
    color: ${COLORS.white};
  }
  option {
    font: italic 200 18px/22px ${FONT.montserrat};
    color: ${COLORS.black};
    opacity: 0.9;
  }

  .hs-error-msg {
    position: absolute;
    bottom: 25px;
    margin-left: 25px;
    margin-right: 5px;
    right: 0;
    font: normal normal 200 12px/16px ${FONT.montserrat};
    &::before {
      content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Ccircle cx="12" cy="12" r="10" /%3E%3Cline x1="12" y1="8" x2="12" y2="12" /%3E%3Cline x1="12" y1="16" x2="12" y2="16" /%3E%3C/svg%3E');
      width: 16px;
      height: 16px;
      display: block;
      left: -20px;
      position: absolute;
    }
    @media (max-width: ${MOBILE_460}px) {
      font-size: 10px;
    }
  }
  textarea {
    height: 120px !important;
  }
  .hs_error_rollup {
    height: 40px;
    position: relative;
    font: normal normal 400 18px/28px ${FONT.montserrat};
    color: ${COLORS.white};
    font-style: italic;
  }
  .hs-button {
    width: 100%;
    height: 60px;
    border: 2px solid #ffffff;
    border-radius: 3px;
    background: none;
    padding: 10px;
    color: white;
    font: normal normal 400 18px/24px ${FONT.montserrat};
    &:hover {
      border-color: #1acafd;
      transition: background-color 0.2s;
      box-shadow: inset 0px 0px 6px #1acafd, 0px 0px 6px #1acafd;
    }
  }
  .hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .hs-input {
      width: 20px !important;
      margin: 0;
      margin-right: 10px;
      visibility: hidden;
    }
    .hs-input:after {
      width: 20px;
      height: 20px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: transparent;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid white;
      margin-top: 19px;
    }
    .hs-input:checked:after {
      width: 20px;
      height: 20px;
      top: -2px;
      left: -1px;
      position: relative;
      visibility: visible;
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00a7ff;
      border: 1px solid #00a7ff;
      margin-top: 19px;
    }
  }
  .multi-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
    gap: 40px;

    @media (max-width: ${MOBILE_460}px) {
      gap: 10px;
      flex-direction: column;
    }
    .hs-form-radio-display {
      margin-right: 10px;
      display: flex;
      margin-top: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .hs-input {
        width: 20px !important;
        margin: 6px;
        height: 20px !important;
        visibility: hidden;
      }
      .hs-fieldtype-radio {
        margin-bottom: 15px;
      }
      .hs-input:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: transparent;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid white;
      }
      .hs-input:checked:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        visibility: visible;
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00a7ff;
        border: 1px solid #00a7ff;
      }
      span {
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .hs-input {
    width: 100% !important;
    background: ${COLORS.white}5f 0% 0% no-repeat padding-box;
    border: none;
    color: white;
    padding: 10px;
    height: 56px;
    margin-top: 6px;
    margin-bottom: 20px;
    opacity: 0.9;
    border-radius: 3px;
    box-sizing: border-box;
    font: normal normal 400 18px/22px ${FONT.montserrat};

    ::placeholder {
      color: ${COLORS.white};
      font: normal italic 400 18px/22px ${FONT.montserrat};
    }
  }
`;
export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${TABLET_800}px) {
  }
`;
export const TextContent = styled.div`
  width: 1100px;
  height: 70vh;
  padding: 29px 17%;
  max-width: 90vw;
  box-sizing: border-box;
  background: transparent linear-gradient(90deg, #6717cd 0%, #4645dd 71%, #00a8ff 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 16px;
  position: relative;
  overflow: auto;
  @media (max-width: ${TABLET_800}px) {
    padding: 29px 20px;
  }
`;
export const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  @media (max-width: ${TABLET_800}px) {
    margin-bottom: 22px;
  }
`;
export const SubTitle = styled.h3<{ bulletIcon?: IBulletICon }>`
  width: 100%;
  font: normal normal 300 22px/32px ${FONT.montserrat};
  text-align: center;
  margin: 30 auto 0 auto;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_800}px) {
    font: normal normal 300 18px/26px ${FONT.montserrat};
  }

  ul {
    padding: 0;
  }

  p {
    margin-bottom: 24px;
  }

  li {
    font-weight: 300;
    margin: 10px 0;
    font-size: 20px;
    line-height: 26px;
    list-style: none;
    display: flex;

    ::before {
      padding-right: 12px;
      ${({ bulletIcon }) =>
        bulletIcon === IBulletICon.Circle &&
        css`
          content: '●';
        `}
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    margin-top: 20px;
    font: normal normal normal 19px/25px ${FONT.montserrat};
  }

  ${({ bulletIcon }) =>
    bulletIcon &&
    bulletIcon === IBulletICon.Check &&
    `li {

      ::before {
        width: 12px;
        height: 12px;
        display: block;
        padding-right: 12px;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12.627" height="9.416" viewBox="0 0 12.627 9.416"><path id="Icon_awesome-check" data-name="Icon awesome-check" d="M4.289,13.809.185,9.7a.631.631,0,0,1,0-.893l.893-.893a.631.631,0,0,1,.893,0l2.765,2.765,5.921-5.921a.631.631,0,0,1,.893,0l.893.893a.631.631,0,0,1,0,.893L5.182,13.809A.631.631,0,0,1,4.289,13.809Z" transform="translate(0 -4.577)" fill="%23fff"/></svg>');
      }
    }
  `};
`;
export const Line = styled.div`
  width: 30%;
  border-bottom: 4px solid ${COLORS.white};
  margin: 20px 0;
`;
export const Text = styled.div`
  font: normal normal medium 20px/28px ${FONT.montserrat};
  color: ${COLORS.white};
  margin-bottom: 27px;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 26px;
  justify-content: center;
  display: flex;
  font: normal 800 normal 46px/58px ${FONT.montserrat};
  @media (max-width: ${TABLET_800}px) {
    font: normal 800 normal 30px/38px ${FONT.montserrat};
  }
`;
