import { COLORS } from '@global/styles/colors';
import { MarkdownProps } from './markdown.types';
import { MarkdownStyles } from './markdown.styles';
import MarkdownView from 'react-showdown';
import React from 'react';

export const lineBreakExtension = {
  type: 'lang',
  regex: /\[([a-zA-z*]{1})\]/g,
  replace: '<br class="break-$1"/>',
};

export const lineBreakExtensionRichText = {
  type: 'lang',
  regex: /\\\[([a-zA-z*]{1})\\\]/g,
  replace: '<br class="break-$1"/>',
};
export const strikethroughExtension = {
  type: 'lang',
  regex: /~(.*)~/g,
  replace: '<s>$1</s>',
};

export const colorTextExtension = {
  type: 'lang',
  regex: /\^\^#([0-9a-fA-F]{6})\^\^/g,
  replace: '<span style="color: #$1;">',
};

export const colorFinishExtension = {
  type: 'lang',
  regex: /\^\^\^\^/g,
  replace: '</span>',
};

export const spanNowrapFormatExtension = {
  type: 'lang',
  regex: /\[\[(.*?)\]\]/g,
  replace: '<span class="nospace">$1</span>',
};

export const colorTextCheckEditorExtension = {
  type: 'lang',
  regex: /\[#([0-9a-fA-F]{6})](.*?)\[\/#]/g,
  replace: '<span style="color: #$1;">$2</span>',
};

export const colorTextCheckEditorExtensionRichText = {
  type: 'lang',
  regex: /\\\[#([0-9a-fA-F]{6})\\](.*?)\\\[\/#\\]/g,
  replace: '<span style="color: #$1;">$2</span>',
};

export const checkImageEditorExtension = {
  type: 'lang',
  regex: /\\\[(check)\\]/g,
  replace: '<span class="renderCheck"> </span>',
};

export const notCheckImageEditorExtension = {
  type: 'lang',
  regex: /\\\[(not-check)\\]/g,
  replace: '<span></span>',
};

export const redirectEditorExtension = {
  type: 'lang',
  regex: /\\\[href: ([^\]]+) color: ([^\]]+)\\\]([^\]]+)\\\[\/href\\\]/g,
  replace: '<a href="$1" style="color: $2; text-decoration:none; outline: none;">$3</a>',
};

const MarkdownParser = ({ text, listMarkerColor, textColor }: MarkdownProps) => {
  return (
    <MarkdownStyles
      listMarkerColor={listMarkerColor && COLORS[listMarkerColor]}
      textColor={textColor && COLORS[textColor]}
    >
      <MarkdownView
        markdown={text || ''}
        extensions={[
          strikethroughExtension,
          colorTextCheckEditorExtension,
          colorTextCheckEditorExtensionRichText,
          colorFinishExtension,
          colorTextExtension,
          spanNowrapFormatExtension,
          lineBreakExtension,
          lineBreakExtensionRichText,
          checkImageEditorExtension,
          notCheckImageEditorExtension,
          redirectEditorExtension,
        ]}
      />
    </MarkdownStyles>
  );
};

export default MarkdownParser;
