import React from 'react';
import OnTapSubscribeModal from '../Modals/OnTapSubscribeModal';
import { Container, OpacityLayer } from '../modalStyles';
import FormSuccessModal from '../Modals/FormSuccessModal';

const RenderSwitch = (modalKey: string, setActiveModal: (modal: string | null) => void) => {
  const normalizeModalKey = modalKey.replaceAll(' ', '').replaceAll('_','').replaceAll('-','').toLowerCase()
  switch (normalizeModalKey) {
    case 'ontapsubscribe':
      return <OnTapSubscribeModal handleClose={() => setActiveModal(null)} />;
    case 'embedformsuccess':
      return <FormSuccessModal handleClose={() => setActiveModal(null)} />;
    default:
      return <></>;
  }
};

const ModalSwitch = ({ modalKey, setActiveModal }: IProps) => {
  return (
    <OpacityLayer>
      <Container>{RenderSwitch(modalKey, setActiveModal)}</Container>
    </OpacityLayer>
  );
};

interface IProps {
  modalKey: string;
  setActiveModal: (modal: string | null) => void;
  options: any;
}

export default ModalSwitch;
