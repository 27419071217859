export const LAYOUT = {
  topbarHeight: '75px',
  topbarMobileHeight: '80px',
  DefaultPromobarHeight: '80px',
  DefaultPromobarMobileHeight: '140px',
};

export const SECTION = {
  paddingTop: '80px',
  paddingBottom: '80px',
  paddingTopMobile: '40px',
  paddingBottomMobile: '40px',
};

export const FONT = {
  defaultFontFamily: 'Montserrat',
  montserrat: 'Montserrat',
  kanit: 'Kanit',
  mulish: 'Mulish',
  sora: 'Sora',
};
