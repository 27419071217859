import { DESKTOP_1024, TABLET_600, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled from 'styled-components';

const MarkdownBaseStyle = styled.div`
  em {
    font-style: italic;
    display: contents;
  }
  strong {
    display: contents;
    font-weight: 800;
  }

  ol {
    list-style-type: decimal;
  }
  ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }
  ol ol ol ol {
    list-style-type: decimal;
  }
  ol ol ol ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol ol ol ol {
    list-style-type: lower-roman;
  }
  ol ol ol ol ol ol ol {
    list-style-type: decimal;
  }
  ol ol ol ol ol ol ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol ol ol ol ol ol ol {
    list-style-type: lower-roman;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    list-style-position: inside;
  }
  ul ul {
    list-style-type: circle;
  }
  ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul {
    list-style-type: disc;
  }
  ul ul ul ul ul {
    list-style-type: circle;
  }
  ul ul ul ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul ul ul ul {
    list-style-type: disc;
  }
  ul ul ul ul ul ul ul ul {
    list-style-type: circle;
  }
  ul ul ul ul ul ul ul ul ul {
    list-style-type: square;
  }
`;

export const MarkdownStyles = styled(MarkdownBaseStyle)<IMarkdownProps>`
  font-family: inherit !important;
  color: ${({ textColor }) => textColor || 'BLACK'};

  div {
    font-family: inherit !important;
  }

  p {
    font-family: inherit !important;
  }

  span {
    font-family: inherit !important;
  }

  strong {
    font-family: inherit !important;
  }

  .nospace {
    white-space: nowrap;
  }

  .break-d {
    @media (max-width: ${DESKTOP_1024 + 1}px) {
      display: none;
    }
  }

  .break-t {
    @media (min-width: ${DESKTOP_1024}px) {
      display: none;
    }

    @media (max-width: ${TABLET_600}px) {
      display: none;
    }
  }

  .break-m {
    @media (min-width: ${TABLET_600 + 1}px) {
      display: none;
    }
  }

  ${(props) => props.color && `color: ${props.color};`}

  body > *:first-child {
    margin-top: 0 !important;
  }
  body > *:last-child {
    margin-bottom: 0 !important;
  }

  a {
    color: inherit;
    text-decoration: none;
    strong {
      color: inherit;
    }
    :hover {
      text-decoration: underline;
    }
  }

  a.anchor {
    display: block;
    padding-left: 30px;
    margin-left: -30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  h2,
  h3,
  h4 {
    margin: 20px 0 10px;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    cursor: text;
    position: relative;
  }

  h1:hover a.anchor,
  h2:hover a.anchor,
  h3:hover a.anchor,
  h4:hover a.anchor {
    text-decoration: none;
  }

  h1 {
    font-size: 55px;
    line-height: 60px;
    font-weight: bold;
  }
  h2 {
    font-size: 40px;
    line-height: 55px;
    font-weight: bold;
  }

  h1 tt,
  h1 code {
    font-size: inherit;
  }

  h2 tt,
  h2 code {
    font-size: inherit;
  }

  h3 tt,
  h3 code {
    font-size: inherit;
  }

  h4 tt,
  h4 code {
    font-size: inherit;
  }

  h2 {
    color: inherit;
  }

  h3 {
    color: inherit;
  }

  h4 {
    color: inherit;
  }

  p,
  ul,
  ol,
  dl,
  li,
  table,
  pre {
    color: inherit;
  }

  hr {
    border: 0 none;
    color: inherit;
    height: 4px;
    padding: 0;
  }

  body > h2:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  body > h1:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  body > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0;
  }
  body > h3:first-child,
  body > h4:first-child,
  body > h5:first-child,
  body > h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  a:first-child h1,
  a:first-child h2,
  a:first-child h3,
  a:first-child h4,
  a:first-child h5,
  a:first-child h6 {
    margin-top: 0;
    padding-top: 0;
  }
  h2 p,
  h3 p,
  h4 p {
    margin-top: 0;
  }
  li p.first {
    display: inline-block;
  }
  li {
    line-height: 22px;
    margin: 0;
    margin-top: 10px;
    color: inherit;
    padding-left: 2px;
    a {
      color: inherit;
      strong {
        color: inherit;
      }
    }
    ::marker {
      color: ${({ color }) => color};
    }
  }

  ul,
  ol {
    padding-left: 20px;
    padding-top: 5px;
  }
  ul :first-child,
  ol :first-child {
    margin-top: 0;
  }

  dl {
    padding: 0;
  }
  dl dt {
    font-weight: bold;
    font-style: italic;
    padding: 0;
    margin: 15px 0 5px;
  }
  dl dt:first-child {
    padding: 0;
  }
  dl dt > :first-child {
    margin-top: 0;
  }
  dl dt > :last-child {
    margin-bottom: 0;
  }
  dl dd {
    margin: 0 0 15px;
    padding: 0 15px;
  }
  dl dd > :first-child {
    margin-top: 0;
  }
  dl dd > :last-child {
    margin-bottom: 0;
  }

  blockquote {
    margin: 10px 0 10px 20%;
    padding-left: 2%;
  }
  blockquote > p {
    color: inherit;
  }

  table {
    padding: 0;
    border-collapse: collapse;
  }
  table tr {
    background-color: #33333312;
    margin: 0;
    padding: 0;
  }
  table tr:nth-child(2n) {
    background-color: #33333312;
  }
  table tr th {
    font-weight: bold;
    margin: 0;
    padding: 6px 13px;
  }
  table tr td {
    margin: 0;
    padding: 6px 13px;
  }
  table tr th :first-child,
  table tr td :first-child {
    margin-top: 0;
  }
  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }

  span.frame {
    display: block;
    overflow: hidden;
  }
  span.frame > span {
    display: block;
    float: left;
    overflow: hidden;
    margin: 13px 0 0;
    padding: 7px;
    width: auto;
  }
  span.frame span img {
    display: block;
    float: left;
  }
  span.frame span span {
    clear: both;
    color: #333333;
    display: block;
    padding: 5px 0 0;
  }
  code,
  tt {
    margin: 0 2px;
    padding: 0 5px;
    white-space: nowrap;
    border: 1px solid #eaeaea;
    background-color: #f8f8f8;
    border-radius: 3px;
  }

  pre code {
    margin: 0;
    padding: 0;
    white-space: pre;
    border: none;
    background: transparent;
  }

  .highlight pre {
    background-color: #f8f8f8;
    line-height: 23px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
  }

  pre {
    background-color: #f8f8f8;
    line-height: 23px;
    overflow: auto;
    padding: 6px 10px;
    border-radius: 3px;
  }
  pre code,
  pre tt {
    background-color: transparent;
    border: none;
  }
  .renderCheck {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-flex;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12.627" height="9.416" viewBox="0 0 12.627 9.416"><path id="Icon_awesome-check" data-name="Icon awesome-check" d="M4.289,13.809.185,9.7a.631.631,0,0,1,0-.893l.893-.893a.631.631,0,0,1,.893,0l2.765,2.765,5.921-5.921a.631.631,0,0,1,.893,0l.893.893a.631.631,0,0,1,0,.893L5.182,13.809A.631.631,0,0,1,4.289,13.809Z" transform="translate(0 -4.577)" fill="%23fff"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 12px;
    left: 44%;
    @media (max-width: ${TABLET_992}px) {
      bottom: 25px;
    }
    @media (max-width: ${TABLET_768}px) {
      width: 12px;
      height: 9px;
      position: unset;
    }
  }
  sup {
    vertical-align: super;
    line-height: 0;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  @media screen and (min-width: 914px) {
    body {
      width: 854px;
      margin: 0 auto;
    }
  }
  @media print {
    table,
    pre {
      page-break-inside: avoid;
    }
    pre {
      word-wrap: break-word;
    }
  }
`;

interface IMarkdownProps {
  textColor?: string;
  listMarkerColor?: string;
}
