export const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  puissantPurple: '#7A19CC',
  brightPurple: '#A813C3',
  brightTurquoise: '#1ACAFD',
  deepseaKraken: '#081E9E',
  pinkPride: '#E21BEA',
  electricPurple: '#A81BEA',
  azure: '#00A8FF',
  warmBlue: '#4457E9',
  gulfBlue: '#05125D',
  mysticMidnightBlue: '#1E296D',
  liver: '#4E4E4E',
  mercury: '#E5E5E5',
  platinum: '#E2E2E2',
  seashell: '#F1F1F1',
  cerulean: '#00A7FF',
  violetBlue: '#5608B9',
  vibrantPurple: '#9D03E1',
  columbiaBlue: '#86DEFF',
  bluePurple: '#6717CD',
  clearBlue: '#2871FA',
  phthaloBlue: '#00158B',
  marbleBlue: '#5B6D79',
  charcoalGrey: '#353F46',
  purplishBlue: '#531BEA',
  dawn: '#A2A2A2',
  jasminePurple: '#9634EC',
  gainsboro: '#DBDBDB',
  artyClickPurple: '#8E15FF',
  blueViolet: '#8919DC',
  ultramarineBlue: '#3D60EC',
  dodgerBlue: '#3A7CEF',
  artyClickMagenta: '#FF00FF',
  violetEggplant: '#7910AA',
  OxfordBlue: '#05125d',
};
