import { IContainerStyles } from '@components/Layout/components/SectionContainer/SectionContainer.types';

export interface ITextColumnsProps {
  title: string;
  subtitle: string;
  paragraph: string;
  textColumns: ITextColumn[];
  cta: CTA;
  bulletIcon?: IBulletICon;
  background?: IContainerStyles;
}

export interface ITextColumn {
  Text_Column: string;
}

interface CTA {
  CTA_Title: string;
  CTA_URL: string;
  CTA_Text_Color: string;
  CTA_Background_Color: string;
  CTA_Hover_Color: string;
}

export enum IBulletICon {
  Circle = 'Circle',
  Check = 'Check',
  Number = 'Number',
}
