import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LottiePlayer, MultipleLottiePlayer } from '@components/LottiePlayer';
import { MediaRenderProps } from './MediaRender.Props';

const MediaRender = ({ mediaSource, alt, play, loop, style, contain }: MediaRenderProps) => {
  // cms graphql image
  if (mediaSource?.data?.attributes?.urlSharp?.childImageSharp) {
    const parsedGastbyImage = getImage(mediaSource?.data?.attributes?.urlSharp?.childImageSharp);
    if (parsedGastbyImage) {
      return (
        <GatsbyImage
          style={{
            width: '100%',
            height: '100%',
            ...style,
            objectFit: contain ? 'contain' : 'cover',
          }}
          image={parsedGastbyImage}
          alt={mediaSource?.data?.attributes?.alternativeText || alt || ''}
        />
      );
    }
  }

  // local graphql image
  if (typeof mediaSource?.images?.fallback?.src === 'string') {
    return (
      <GatsbyImage
        style={{
          width: '100%',
          height: '100%',
          ...style,
          objectFit: contain ? 'contain' : 'cover',
        }}
        image={mediaSource}
        alt={alt || ''}
      />
    );
  }

  // cms graphql lottie
  if (
    mediaSource?.data?.attributes?.url &&
    mediaSource?.data?.attributes?.mime === 'application/json'
  ) {
    const jsonString = mediaSource?.data?.attributes?.url;
    return <MultipleLottiePlayer loop={loop} play={play} animationArrayData={jsonString} />;
  }

  // cms graphql svg
  if (
    mediaSource?.data?.attributes?.url &&
    mediaSource?.data?.attributes?.mime === 'image/svg+xml'
  ) {
    const url = mediaSource?.data?.attributes?.url;
    return <img src={url} alt={mediaSource?.data?.attributes?.alternativeText || alt || ''} />;
  }

  // cms preview lottie
  if (mediaSource?.ext === '.json' && mediaSource?.url) {
    const jsonURL = mediaSource?.url;
    return <MultipleLottiePlayer play={play} loop={loop} animationArrayData={jsonURL} />;
  }

  // cms preview image
  if (typeof mediaSource?.url === 'string') {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
          ...style,
          objectFit: contain ? 'contain' : 'cover',
        }}
        src={mediaSource.url}
        alt={mediaSource.alternativeText || alt || ''}
      />
    );
  }

  // cms graphql image fallback
  if (mediaSource?.data?.attributes?.url?.includes('https')) {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
          ...style,
          objectFit: contain ? 'contain' : 'cover',
        }}
        src={mediaSource?.data?.attributes?.url}
        alt={mediaSource?.data?.attributes?.alternativeText || ''}
      />
    );
  }

  // local lottie
  if (typeof mediaSource === 'object') {
    return <LottiePlayer animationData={mediaSource} loop={loop} play={play} />;
  }

  // local src
  if (typeof mediaSource === 'string') {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
          ...style,
          objectFit: contain ? 'contain' : 'cover',
        }}
        src={mediaSource}
        alt={alt || ''}
      />
    );
  }

  return <div className="no-image-found" />;
};

const defaultProps = {
  play: true,
  contain: false,
};

MediaRender.defaultProps = defaultProps;

export default MediaRender;
