import React, {
  useState,
  useMemo,
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from 'react';

import ModalSwitch from '@components/Modals/ModalSwitch';

const defaultState = {
  activeModal: '',
  triggerModal: () => {},
};

interface IModalState {
  // todo: type the data object
  activeModal: string | null;
  triggerModal: (modal: string | null, modalOptions?: object | null) => void;
}

interface IProviderProps {
  children: ReactNode;
}

const ModalContext = createContext<IModalState>(defaultState);

const ModalProvider: FunctionComponent<IProviderProps> = ({ children }: IProviderProps) => {
  const [activeModal, setActiveModal] = useState<any>('');
  const [options, setOptions] = useState<any>({});

  const updateStates = (modal: string | null, modalOptions: object | null) => {
    setActiveModal(modal);
    setOptions(modalOptions);
  };

  const value = useMemo(
    () => ({ activeModal, options, triggerModal: updateStates }),
    [activeModal, options],
  );

  useEffect(() => {
    const currentUrl = window.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const modal = urlParams.get('modal');

    if (modal) {
      setActiveModal(modal);
    }
  }, []);

  return (
    <ModalContext.Provider value={value}>
      {activeModal && (
        <ModalSwitch modalKey={activeModal} setActiveModal={setActiveModal} options={options} />
      )}
      {children}
    </ModalContext.Provider>
  );
};
export default ModalContext;
export { ModalProvider };
