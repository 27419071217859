/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { CSSProperties } from 'styled-components';
import { LottiePlayer } from './LottiePlayer';

export const MultipleLottiePlayer: React.FC<IProps> = (props: IProps) => {
  const { animationArrayData, containerStyle, play, loop } = props;
  const [fetchedAnimationArrayData, setFetchedAnimationArrayData] = useState<any>(null);

  useEffect(() => {
    const fetchRemoteJSON = async () => {
      fetch(animationArrayData)
        .then((res) => res.json())
        .then((out) => {
          if (!out?.length) {
            const animationsArray = [out];
            setFetchedAnimationArrayData(animationsArray);
          } else {
            setFetchedAnimationArrayData(out);
          }
        })
        .catch((err) => {
          throw err;
        });
    };

    if (
      typeof animationArrayData === 'string' &&
      animationArrayData.match(
        // eslint-disable-next-line
        /(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)(.json)/g,
      )
    ) {
      fetchRemoteJSON();
    } else {
      setFetchedAnimationArrayData(animationArrayData);
    }
  }, [animationArrayData]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...containerStyle,
      }}
      className="lottie--container"
    >
      {fetchedAnimationArrayData &&
        fetchedAnimationArrayData?.length > 0 &&
        fetchedAnimationArrayData.map((animation: any, index: number) => {
          return (
            <LottiePlayer
              containerStyle={{ position: 'absolute' }}
              animationData={animation?.animationData ? animation?.animationData : animation}
              loop={loop}
              play={play}
              animationHeight="auto"
              animationWidth="100%"
              key={animation.nm + index}
            />
          );
        })}
    </div>
  );
};

interface IProps {
  containerStyle?: CSSProperties;
  animationArrayData: any;
  play?: boolean;
  loop?: boolean;
}
const defaultProps = {
  containerStyle: {},
  play: true,
  loop: false,
};
MultipleLottiePlayer.defaultProps = defaultProps;
